export const editorOptions = {
  data() {
    return {
      editorOptions: {
        theme: "snow",
        placeholder: "Текст сообщения...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"]
          ],
          keyboard: {
            bindings: {
              tab: false,
              handleEnter: {
                key: 13,
                handler: function() {
                  // Do nothing
                }
              }
            }
          }
        },
        formats: [
          "background",
          "bold",
          "color",
          "font",
          "code",
          "italic",
          "link",
          "size",
          "strike",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "direction",
          "code-block",
          "formula"
        ]
      }
    };
  }
};
