<template>
  <div class="ticket">
    <img
      class="loading"
      v-if="loadingTicket === 'LOADING'"
      src="../assets/loading-black.svg"
      alt=""
    />

    <div class="ticket-info" v-if="loadingTicket === 'SUCCESS' && ticket">
      <div class="ticket-info__title">
        <button class="ticket-info__btn" @click="$router.push('/')">
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 9L1 5L5 1" stroke="#221122" stroke-opacity="0.4" />
          </svg>
          Назад
        </button>

        <div class="title-user">
          <p class="title-user__name">Диалог</p>
        </div>

        <div class="title-type">
          <p>{{ ticket.theme.name }}</p>
        </div>
      </div>

      <div class="chat">
        <div
          :class="[
            'message',
            message.role === 'support' ? 'message--support' : 'message--user'
          ]"
          v-for="(message, index) in messages"
          :key="index"
        >
          <h6 class="message__name">{{ message.author }}</h6>

          <quill-editor
            class="message__text"
            :content="cleanMessage(message.text)"
            :options="messageOption"
            :disabled="true"
          ></quill-editor>

          <div class="message__attachments attachments">
            <div
              class="attachments__item"
              v-for="attachment in message.attachments"
              :key="attachment.id"
              @click="loadFile({ id: attachment.id, name: attachment.name })"
            >
              <div
                class="attachments__img"
                v-if="
                  attachment.type === 'jpg' ||
                    attachment.type === 'png' ||
                    attachment.type === 'jpeg'
                "
              >
                <img
                  :src="BASE_URL + 'api/attachments/' + attachment.id"
                  height="auto"
                  width="100%"
                  alt=""
                />

                <p>{{ attachment.name }}</p>
              </div>

              <div class="attachments__wrapper" v-else>
                <p class="attachments__name">
                  {{ attachment.name.split(".")[0] }}
                </p>
                <p class="attachments__type">{{ attachment.type }}</p>
              </div>
            </div>
          </div>

          <p class="message__date">
            {{ message.created_at + " #" + $route.params.id }}
          </p>
        </div>
      </div>

      <div class="ticket-info__editor" v-if="showChat">
        <div class="editor__wrapper">
          <quill-editor
            class="editor"
            v-model="content"
            :options="editorOptions"
            @keydown.enter.native="handleKeypress($event)"
          >
          </quill-editor>

          <button
            class="editor__btn"
            :class="sending || !content ? 'editor__btn--disabled' : ''"
            @click="submitMessage"
            :disabled="sending || !content"
          ></button>
        </div>

        <div class="editor__files">
          <div class="input" v-show="files.length">
            <label>
              Вложения:
              <input
                type="file"
                id="files"
                ref="files"
                multiple
                v-on:change="handleFilesUpload()"
              />
            </label>
          </div>

          <div class="names" v-show="files.length">
            <div v-for="(file, key) in files" :key="key" class="file-listing">
              <p>{{ file.name }}</p>

              <span class="remove-file" v-on:click="removeFile(key)">
                Удалить
              </span>
            </div>
          </div>

          <div class="btns">
            <button v-on:click="addFiles">Прикрепить файл</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { BASE_URL } from "@/main";
import { editorOptions } from "@/components/mixins/editorOptions.mixin";

export default {
  name: "TicketUser",
  mixins: [editorOptions],

  data() {
    return {
      BASE_URL: BASE_URL,
      files: [],
      sending: false,
      modal: false,
      content: "",
      showChat: true,

      messageOption: {
        theme: "bubble",
        modules: {
          toolbar: false
        },
        formats: [
          "background",
          "bold",
          "color",
          "font",
          "code",
          "italic",
          "link",
          "size",
          "strike",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "direction",
          "code-block",
          "formula"
        ]
      }
    };
  },

  watch: {
    "$route.params.id": function() {
      if (Number.isInteger(parseInt(this.$route.params.id))) {
        this.content = "";
        this.loadTicketRequest(this.$route.params.id);
        this.$echo
          .channel(`tickets.${this.$route.params.id}`)
          .listen("MessageCreate", () => {
            if (this.$route.params.id) {
              this.loadMessages({ id: this.$route.params.id, user: true });
            }
          })
          .listen("MessageUpdate", () => {
            if (this.$route.params.id) {
              this.loadMessages({ id: this.$route.params.id, user: true });
            }
          })
          .listen("TicketUpdate", () => {
            if (this.$route.params.id) {
              let id = parseInt(this.$route.params.id);
              let obj = this.tickets.find(item => item.id === id);
              if (obj.status === "done") {
                if (this.showChat) {
                  this.showChat = false;
                }
                return;
              }
              this.showChat = true;
            }
          });
      }
    }
  },

  computed: {
    ...mapState({
      loadingTicket: state => state.ticket.loadingTicket,
      ticket: state => state.ticket.ticket,
      messages: state => state.message.messages,
      tickets: state => state.ticket.tickets
    })
  },

  methods: {
    ...mapMutations({
      addMessage: "message/ADD_MESSAGE",
      setLoading: "ticket/SET_LOADING"
    }),

    ...mapActions({
      loadTicket: "ticket/loadTicket",
      sendMessage: "message/sendMessage",
      loadMessages: "message/loadMessages",
      loadFile: "message/loadFile",
      sendFiles: "message/sendFiles"
    }),

    cleanMessage(message) {
      return this.$sanitize(message);
    },

    addFiles() {
      this.$refs.files.click();
    },

    removeFile(key) {
      this.files.splice(key, 1);
    },

    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (let i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },

    handleKeypress($event) {
      if (
        !$event.shiftKey &&
        $event.key === "Enter" &&
        this.content &&
        !this.sending
      ) {
        this.submitMessage();
      }
    },

    submitMessage() {
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append("attachments[" + i + "]", file);
      }

      this.sending = true;

      if (this.files.length) {
        this.sendFiles(formData)
          .then(response => {
            this.sendMessage({
              id: this.ticket.id,
              content: this.content,
              attachments: response.data.attachments,
              user: true
            })
              .then(() => {
                this.content = "";
                this.files = [];
                this.sending = false;
              })
              .catch(() => {
                this.sending = false;
              });
          })
          .catch(() => {
            this.sending = false;
          });
      } else {
        this.sendMessage({
          id: this.ticket.id,
          content: this.content,
          user: true
        })
          .then(() => {
            this.content = "";
            this.files = [];
            this.sending = false;
          })
          .catch(() => {
            this.sending = false;
          });
      }
    },

    loadTicketRequest(id) {
      if (Number.isInteger(parseInt(id))) {
        this.setLoading({ type: "ticket", value: "LOADING" });

        this.loadMessages({ id: id, user: true }).then(() => {
          this.loadTicket({ id: id, user: true });
        });
      }
    }
  },

  mounted() {
    if (Number.isInteger(parseInt(this.$route.params.id))) {
      this.loadTicketRequest(this.$route.params.id);
      this.$echo
        .channel(`tickets.${this.$route.params.id}`)
        .listen("MessageCreate", () => {
          if (this.$route.params.id) {
            this.loadMessages({ id: this.$route.params.id, user: true });
          }
        })
        .listen("MessageUpdate", () => {
          if (this.$route.params.id) {
            this.loadMessages({ id: this.$route.params.id, user: true });
          }
        })
        .listen("TicketUpdate", () => {
          if (this.$route.params.id) {
            let id = parseInt(this.$route.params.id);
            let obj = this.tickets.find(item => item.id === id);
            if (obj.status === "done") {
              if (this.showChat) {
                this.showChat = false;
              }
              return;
            }
            this.showChat = true;
          }
        });
    }
  }
};
</script>

<style lang="scss">
.ticket {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;

  .ticket-info {
    background: #ffffff;
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    &__btn {
      font-size: 18px;
      line-height: 18px;
      color: $label-third;
      border: none;
      outline: none;
      background-color: transparent;
      position: absolute;
      left: 24px;
      top: 24px;
      align-items: center;
      display: none;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }
    }

    &__title {
      padding: 24px 40px 24px;
      border-bottom: 1px solid #eeeeee;
      position: relative;

      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f5f6fa;
        flex: none;
        padding: 24px 20px 24px;

        .ticket-info__btn {
          display: flex;
          left: 0;
        }
      }

      .title-user {
        display: flex;
        margin-bottom: 12px;

        &__name {
          margin-right: 8px;
          font-weight: 500;
          font-size: 18px;
        }
      }

      .title-type {
        p {
          font-size: 18px;
          line-height: 22px;
          max-width: 90%;
          color: $label-second;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

          @media (max-width: 768px) {
            max-width: 100%;
          }
        }
      }
    }

    &__editor {
      width: 100%;
      padding: 25px 40px 15px 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      margin-top: auto;
      word-break: break-word;
      flex: none;

      @media (max-width: 768px) {
        padding: 25px 20px 15px;
      }
    }

    .chat {
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column-reverse;
      word-break: break-word;
      padding: 0 40px 0 50px;

      @media (max-width: 768px) {
        padding: 0 20px;
      }

      @media (max-width: 1140px) {
        max-width: 100%;
      }

      .message {
        padding: 16px 20px 12px 16px;
        border-radius: 6px;
        margin-top: 24px;
        max-width: 70%;

        @media (max-width: 1140px) {
          max-width: 100%;
        }

        &__name {
          font-size: 14px;
          font-weight: 500;
          color: $third;
          margin-bottom: 8px;
        }

        &__text {
          .ql-editor {
            padding: 0;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 12px;
            font-family: "Rubik", sans-serif;
          }
        }

        &__date {
          font-size: 10px;
          line-height: 12px;
        }
      }

      .message--user {
        background: $gold-secondary;
        margin-left: auto;

        .message__name {
          color: $label-main;
        }

        .message__text {
          .ql-editor {
            color: $label-main;
          }
        }

        .message__date {
          color: $label-second;
        }
      }

      .message--support {
        background: #f4f5f9;
        margin-right: auto;

        .message__name {
          color: $label-main;
        }

        .message__text {
          .ql-editor {
            color: $label-second;
          }
        }

        .message__date {
          color: $label-third;
        }
      }

      .attachments {
        margin-bottom: 10px;
        display: flex;

        &__item {
          height: 64px;
          width: 64px;
          overflow: hidden;
          border-radius: 8px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: 10px;
          background-color: $label-main;

          p {
            white-space: nowrap;
          }
        }

        &__img {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            position: absolute;
            margin: auto;
            left: 6px;
            max-width: 54px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 10px;
            color: white;
            text-align: center;
            width: 100%;
            background-color: rgba(#212121, 0.65);
            border-radius: 2px;
            padding: 1px 2px;
          }
        }

        &__wrapper {
          padding: 6px;
          color: $third;
        }

        &__name {
          font-size: 10px;
          max-width: 52px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &__type {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      &__btns {
        margin-top: 24px;
        padding-bottom: 24px;

        @media (max-width: 425px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        button {
          padding: 16px 0;
          width: 162px;
          border-radius: 6px;
          border: none;
          outline: none;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          cursor: pointer;
          transition: transform 0.08s linear;

          &:hover {
            transform: scale(1.05);
          }

          &:active {
            transform: scale(1);
          }
        }
      }

      .btns {
        &__first {
          margin-right: 40px;
          background-color: $main;
          color: $third;

          @media (max-width: 425px) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        &__second {
          background-color: #f5f6fa;
          color: $main;
        }
      }
    }
  }

  .editor__wrapper {
    position: relative;

    .editor__btn {
      position: absolute;
      right: 32px;
      bottom: 20px;
      height: 24px;
      width: 24px;
      background-color: $label-main;
      border-radius: 50%;
      border: none;
      outline: none;
      background-image: url("../assets/icons/arrow-right--gold.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: transform 0.08s linear;

      &:hover {
        transform: scale(1.08);
      }

      &:active {
        transform: scale(1);
      }

      &--disabled {
        opacity: 0.7;

        &:hover {
          transform: none;
        }
      }
    }
  }

  .editor__files {
    margin-top: 16px;

    .input {
      margin-bottom: 10px;
    }

    input[type="file"] {
      position: absolute;
      top: 500px;
    }

    .file-listing {
      display: flex;
      font-size: 13px;

      p {
        line-height: 16px;
        max-width: 65%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      span {
        margin-left: 15px;
        align-self: center;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    .names {
      margin-bottom: 16px;
    }

    span.remove-file {
      color: red;
      cursor: pointer;
      float: right;
    }

    .btns {
      button {
        border: none;
        outline: none;
        padding: 6px 15px;
        border-radius: 4px;
        color: $label-main;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .quill-editor.editor {
    background: #f5f6fa;
    border-radius: 4px;
    padding: 10px 16px;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ebecf2;
  }

  .ql-container.ql-snow {
    border: none;
  }
}
</style>
